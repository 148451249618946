import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import parse from 'html-react-parser'

import * as S from './Popup.styled'

import { ReactComponent as Print } from '@/assets/svg/print.svg'
import { ReactComponent as Download } from '@/assets/svg/file-download.svg'
import { ReactComponent as LinkExternal } from '@/assets/svg/link-external.svg'
// import { ReactComponent as OpenAI } from '@/assets/svg/openai-logomark.svg'

import { DocItem, FileViewer, Loading, SectionTitle } from '@/components'

import { documentDataType } from '@/types/documentsType'
import { keywordHighlighting, hasPopupSource } from '@/functions'

import { useSelector } from '@/store'
import { useDispatch } from 'react-redux'
import { searchSagaActions } from '@/store/saga/searchSaga'
import { linebreakParse } from '@/functions/linebreakParse'
import { css } from '@emotion/css'
import axios from '@/services'
import { useTranslation } from 'react-i18next'

export function Document() {
  /** router */
  const [searchParams] = useSearchParams()
  const keyword = searchParams.get('keyword')

  /** redux */
  const dispatch = useDispatch()
  const { detailData, loading } = useSelector((state) => state.search)

  const {
    title,
    date,
    contents,
    file,
    data_src_l_cd: dataSrcLCd,
    data_src_m_cd: dataSrcMCd,
    data_src_s_cd: dataSrcSCd,
    source_url: sourceUrl,
    col_dt: colDt,
    associative_images: associativeImages,
    similar_documents: similarDocuments,
    sentences_inner_hits: sentencesInnerHits,
    page_no,
    trans_pdf_path,
  } = detailData as documentDataType

  const seeker = sentencesInnerHits ? [...sentencesInnerHits].sort((a, b) => a.start - b.start) : []

  // const [openAiLoading, setOpenAiLoding] = useState<boolean>(false)
  // const [openAiResult, setOpenAiResult] = useState<string>()

  const [selectedAssociativeImage, setSelectedAssociativeImage] = useState<string>()

  const { t } = useTranslation()

  // const getOpenAISummuny = async () => {
  //   const result = await fetch(`${process.env.REACT_APP_BASE_URL}/summary`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       text: contents,
  //     }),
  //   })

  //   return result.json()
  // }

  // const handleOpenAiSummuny = async () => {
  //   setOpenAiLoding(true)
  //   await getOpenAISummuny().then((res) => {
  //     switch (res.code) {
  //       case '-1':
  //         setOpenAiResult('OpenAI Server Error')

  //         break

  //       case '0':
  //         setOpenAiResult('Internal Server Error')

  //         break

  //       default:
  //         setOpenAiResult(res.message)

  //         break
  //     }
  //   })

  //   setOpenAiLoding(false)
  // }

  /** 유사문서 클릭 시 데이터 변경 */
  const handleChangeInnerData = useCallback(
    (e: React.MouseEvent) => {
      dispatch(searchSagaActions.setDetailDataAction({ id: e.currentTarget.id, type: 'doc' }))
    },
    [dispatch],
  )

  const handleKeydownChangeInnerData = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.code !== 'Enter') return
      dispatch(searchSagaActions.setDetailDataAction({ id: e.currentTarget.id, type: 'doc' }))
    },
    [dispatch],
  )

  /** 유사문서 클릭 시 로딩 화면 */
  if (loading) {
    return (
      <S.PopupLayout
        className='wrap_popup'
        $loading={loading}
        style={{ height: 'calc(100vh - 151px)' }}
      >
        <div className='popup_info'>
          <div className='info_detail'>
            <dl>
              <div>
                <dt>{t('components.Popup.Src')}</dt>
                <dd></dd>
              </div>
              <div>
                <dt>업데이트 날짜</dt>
                <dd></dd>
              </div>
            </dl>
          </div>
          <div className='info_cotrol'>
            <a href='#none'>
              <Print />
              {t('components.Popup.Print')}
            </a>
            <a href='#none'>
              <Download />
              {t('components.Popup.Download')}
            </a>
          </div>
        </div>
        <div className='popup_content' style={{ height: 'calc(100% - 68px)' }}>
          <Loading
            className={css`
              height: 100%;
            `}
          />
        </div>
      </S.PopupLayout>
    )
  }

  return (
    <>
      <style>{S.printStyle}</style>
      <S.PopupLayout className='wrap_popup'>
        <div className='popup_info'>
          <div className='info_detail'>
            <dl>
              <div>
                <dt>{t('components.Popup.Src')}</dt>
                <dd>
                  <span>{hasPopupSource(dataSrcLCd, dataSrcMCd, dataSrcSCd)}</span>
                  {sourceUrl !== '' && (
                    <a href={sourceUrl} target='_blank' rel='noreferrer noopener'>
                      <LinkExternal />
                    </a>
                  )}
                </dd>
              </div>
              <div>
                <dt>{t('components.Popup.Pub')}</dt>
                <dd>
                  <span>{date.slice(0, -9).replaceAll('-', '. ')}.</span>
                </dd>
              </div>
              <div>
                <dt>{t('components.Popup.Col')}</dt>
                <dd>
                  <span>{colDt.slice(0, -9).replaceAll('-', '. ')}.</span>
                </dd>
              </div>
            </dl>
          </div>
          <div className='info_cotrol'>
            <a href='#none' onClick={() => window.print()}>
              <Print />
              {t('components.Popup.Print')}
            </a>
            {file && <PdfDownloader file={file} />}
          </div>
        </div>
        <div className='popup_content'>
          <div className='content_txt'>
            {keyword !== null && (
              <>
                <h3>
                  {linebreakParse(
                    keywordHighlighting(title ?? `${contents.slice(0, 20)}...`, keyword),
                  )}
                </h3>

                {sourceUrl ? (
                  <a href={sourceUrl} target='_blank' rel='noopener noreferrer'>
                    {file ? (
                      <div style={{ display: 'flex' }}>
                        <div style={{ maxWidth: '100%', border: '1px solid #e1e1e1' }}>
                          <FileViewer
                            file={file}
                            contents={contents}
                            keyword={keyword}
                            page={page_no ? page_no : 1}
                            trans_pdf_path={trans_pdf_path}
                          />
                        </div>
                        {seeker.length > 0 ? (
                          <div
                            className='sentences_inner_hits'
                            style={{ flex: '1 1 auto', height: 'auto', border: 0 }}
                          >
                            {seeker.map(
                              (sentence, index) =>
                                keyword !== null && (
                                  <p
                                    key={index}
                                    title={sentence.text}
                                    style={{ cursor: 'default' }}
                                  >
                                    <span>
                                      {parse(keywordHighlighting(sentence.text ?? '', keyword))}
                                    </span>
                                  </p>
                                ),
                            )}
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div>
                        <p>{linebreakParse(keywordHighlighting(contents, keyword))}</p>
                      </div>
                    )}
                  </a>
                ) : file ? (
                  <div style={{ display: 'flex' }}>
                    <div style={{ maxWidth: '100%', border: '1px solid #e1e1e1' }}>
                      <FileViewer
                        file={file}
                        contents={contents}
                        keyword={keyword}
                        page={page_no ? page_no : 1}
                        trans_pdf_path={trans_pdf_path}
                      />
                    </div>
                    {seeker.length > 0 ? (
                      <div
                        className='sentences_inner_hits'
                        style={{ flex: '1 1 auto', height: 'auto', border: 0 }}
                      >
                        {seeker.map(
                          (sentence, index) =>
                            keyword !== null && (
                              <p key={index} title={sentence.text} style={{ cursor: 'default' }}>
                                <span>
                                  {parse(keywordHighlighting(sentence.text ?? '', keyword))}
                                </span>
                              </p>
                            ),
                        )}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <p>{linebreakParse(keywordHighlighting(contents, keyword))}</p>
                  </div>
                )}
              </>
            )}
          </div>
          {associativeImages.data.length !== 0 && (
            <div className='content_img'>
              <strong>{t('components.Popup.RelatedImage')}</strong>
              <div className='box_table'>
                {associativeImages.data.slice(0, 10).map((item, index) => {
                  return (
                    <figure
                      className='box_tableItem'
                      key={item.id}
                      title={`${title}관련 이미지`}
                      onClick={() => {
                        setSelectedAssociativeImage(`${process.env.REACT_APP_BASE_URL}${item.file}`)
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}${item.file}`}
                        alt={`${title}관련 이미지 ${index + 1}`}
                        width={168}
                        height={91}
                      />
                    </figure>
                  )
                })}
              </div>
              {selectedAssociativeImage != undefined ? (
                <figure className='selectedImg' title={`${title}관련 이미지`}>
                  <img src={selectedAssociativeImage} />
                </figure>
              ) : null}
              <div></div>
            </div>
          )}
        </div>
        {similarDocuments.data && (
          <div className='content_docList'>
            <SectionTitle title='유사문서' />
            <ul>
              {similarDocuments.data.slice(1, 5).map((item) => (
                <DocItem
                  key={item.id}
                  id={item.id}
                  dataItem={item}
                  onClick={handleChangeInnerData}
                  onKeyDown={handleKeydownChangeInnerData}
                  tabIndex={0}
                />
              ))}
            </ul>
          </div>
        )}
      </S.PopupLayout>
    </>
  )
}

interface PdfDownloaderProps {
  file: string
}

function PdfDownloader({ file }: PdfDownloaderProps) {
  const [pdfUrl, setPdfUrl] = useState('')
  const [filename, setFilename] = useState('')

  const { t } = useTranslation()

  useEffect(() => {
    const fileName = file.split('/').pop() || 'download.pdf'
    setFilename(fileName)

    const fetchPdfFile = async () => {
      try {
        const response = await axios.post(
          '/file',
          {
            file_path: file.replace('/file/', ''), // 파일 경로를 파라미터로 전달
          },
          {
            responseType: 'blob',
          },
        )

        if (response.status !== 200) {
          throw new Error('서버 에러')
        }

        const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
        const pdfFileUrl = URL.createObjectURL(pdfBlob)
        setPdfUrl(pdfFileUrl)
      } catch (error) {
        console.error('PDF 파일을 불러오는 중 에러 발생:', error)
      }
    }

    fetchPdfFile()

    // 컴포넌트 언마운트 시, 생성한 Object URL을 정리합니다.
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]) // file이 변경될 때만 useEffect 내부 로직을 실행합니다.

  return (
    <a href={pdfUrl} download={filename}>
      <Download />
      {t('components.Popup.Download')}
    </a>
  )
}

export default PdfDownloader
